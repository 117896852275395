import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { cartdata } from 'src/app/pages/cart/data';
import { ModeOrder, Store } from 'src/app/core/models/mode-order.model';
import { ModeOrderService } from 'src/app/services/ecommerce/mode-order.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { EcommerceService } from 'src/app/services/ecommerce/ecommerce.service';
import { LanguageService } from 'src/app/services/global/language.service';
import { CookieService } from 'ngx-cookie-service';
import { CompanyService } from 'src/app/services/global/company.service';
import { StoreService } from 'src/app/services/ecommerce/store.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrderService } from 'src/app/services/ecommerce/order.service';
import { ErrorManagementService } from 'src/app/services/global/error-management.service';
import { AdditionalService } from 'src/app/services/ecommerce/additional.service';
import { PROVIDER } from 'src/environments/config';
import * as moment from 'moment';
import { ConfirmComponent } from 'src/app/pages/others/confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';

interface Aditional {
  id: number;
  code: string;
  name: string;
  price: number;
  quantity: number;
  required: string;
}

interface OrderDateHour {
  id: number;
  name: string;
  orderDateHour: Date | null; // manejar fechas en js
  formattedHour: string;
  quantity: number;
  itemCode: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public isCollapsed = true;
  carts: any;
  total: any = 0;
  term: any;
  cartList: any[] = []
  //Languages
  selectedLanguage: any;
  flag: any;
  countryName: any;
  cookieValue: any;
  flagvalue: any;
  valueset: any;

  obs: Subscription[] = []
  _user: any = null

  mode: ModeOrder = new ModeOrder();
  selected = null
  subSelected = null
  totalPrice: number = 0;


  hours: OrderDateHour[] = [];
  formData!: UntypedFormGroup;
  qty: any = 1;
  cartproduct: any;
  totalprice: any = 0;
  submitted = false;
  deliveryTax: number = 0;
  storeName: string = '';
  subOrder: string = '';
  orderSelected: string = ''
  data: any = null
  promoCode: string = '';
  isStoreOpen: boolean = true; //definir la hora inicial de la tienda
  @Output() orderGenerated = new EventEmitter<string>();
  selectedTimeSlot: OrderDateHour | null = null;
  listGenerateQueues: OrderDateHour[][] = [];
  currentVariant: number[] = [];
  totalOrderAmount: number = 0;
  orderDetails: any;
  isLoading: boolean = false;
  storeSelected: any;
  isDropdownOpen: boolean = false;

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  constructor(
    public formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    public translate: TranslateService,
    public router: Router,
    private authService: AuthenticationService,
    private orderModeService: ModeOrderService,
    public ecommerceService: EcommerceService,
    public languageService: LanguageService,
    public _cookiesService: CookieService,
    private companyService: CompanyService,
    private dialog: MatDialog,
    private additionalService: AdditionalService,
    private errorService: ErrorManagementService,
    private modeOrderService: ModeOrderService,

    public orderService: OrderService,
    public snackBar: MatSnackBar,
    private storeService: StoreService
  ) {
    translate.setDefaultLang('es');

    this.obs.push(
      this.authService.currentUser.subscribe(res => {
        this._user = res == null ? null : res.user
        if (this._user) {
          this.getListCart()
        }
      })
    )

    this.obs.push(
      this.companyService.getConfigCompany().subscribe(async res => {
        this.deliveryTax = res.deliveryTax;
      })
    )

    this.obs.push(
      this.modeOrderService.currentMode.subscribe(async res => {
        if (res && res.storeSelected) {
          this.storeName = res.storeSelected.name
          this.data = res
          this.storeSelected = res.storeSelected;

          this.subOrder = this.getOrderDescription(res.subOrderSelected);
        }
      })
    )

    this.generatedHour(new Date());
    setInterval(() => {
      this.updateGeneratedHours();
    }, 60000); // 60 segundos

    this.obs.push(
      this.orderModeService.currentMode.subscribe((res: any) => {
        this.mode = res
        /* if (res == null) {
          this.selected = null
          let object = new ModeOrder()
          object.orderSelected = 'PD'

          this.orderModeService.updateCurrentModeOrder(object)
        } else {
          this.selected = this.mode.orderSelected
          if (this.mode.orderSelected == null) {
            let object = new ModeOrder()
            object.orderSelected = 'PD'
            this.orderModeService.updateCurrentModeOrder(object)
          }
        }
        if (res == null) {
          this.subSelected = null
        } else {
          this.subSelected = this.mode.subOrderSelected
        } */
      })
    )
  }

  city = {
    code: '',
    name: ''
  }

  stations: any[] = [];

  ngOnInit(): void {
    this.carts = cartdata

    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.flagvalue = 'assets/img/flags/es.png'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }

    if(this._user){
      this.ecommerceService.getCart().subscribe(res => {
        this.cartList = res || [];
        this.calculateTotalPrice();
        console.log('Carrito cargado ', this.cartList);
      });

      this.ecommerceService.totalPriceChange.subscribe(total => {
        this.totalPrice = total;
        console.log("cambio total price", this.totalPrice);

      });

      this.ecommerceService.cartListChange.subscribe(cartList => {
        this.cartList = cartList;
      });
    }
    else{
      this.cartList = [];
      this.totalPrice = 0;
    }

    this.selectedLanguage = 'Es / $'
    const startDate = new Date();
    this.generatedHour(startDate);
  }

  getStores() {
    this.storeService.getListStoreByProvider({
      business_partner: PROVIDER.providerId,
      cityCode: this.city.code,
      pickPosition: this.mode.subOrderSelected

    }).subscribe((res: any) => {
      this.stations = res
    })
  }



  generatedHour(date: Date) {
    // Hora actual
    let currentDate = new Date();
    // Lista para almacenar las horas generadas
    const datesAdded: OrderDateHour[] = [];
    // validacion si la tienda selecionada tiene horario laboral
    if (!this.data || !this.data.storeSelected || !this.data.storeSelected.general_range_hour || !this.data.storeSelected.general_range_hour.length) {
      return;
    }
    // obtener las horas de inicio y cierre desde el campo general_range_hour de la tienda
    const storeHours = this.data.storeSelected.general_range_hour[0].name.split(' '); //ejemplo 06:45 19:30
    const storeOpeningTime = storeHours[0]; // Horario de inicio 06:45
    const storeClosingTime = storeHours[1]; // Horario de cierre 19:30
    // convertir lah hora de inicio y la hora de cierre en objetos date Date
    const [openingHour, openingMinute] = storeOpeningTime.split(':').map(Number);
    const [closingHour, closingMinute] = storeClosingTime.split(':').map(Number);
    const storeOpeningDate = new Date();
    //hora de inicio
    storeOpeningDate.setHours(openingHour, openingMinute, 0, 0);
    const storeClosingDate = new Date();
    //hora de cierre
    storeClosingDate.setHours(closingHour, closingMinute, 0, 0);
    // el rango de horas que se definen hasta donde se generarán las horas (se dejo maximo 2 horas)
    const endDate: Date = new Date(currentDate.getTime() + 2 * 60 * 60 * 1000); // 2 horas
    // validacion si la tienda esta abierta
    this.isStoreOpen = currentDate < storeClosingDate;
    // si la tienda esta cerrada, mostrar un mensaje
    if (!this.isStoreOpen) {
      datesAdded.push({
        id: 1,
        name: `No hay horas disponibles`,
        orderDateHour: null, //hora no valida
        formattedHour: '',
        quantity: 0,
        itemCode: '0',
      });
      this.hours = datesAdded;
      return;
    }

    // verificar que las horas generadas estén dentro del horario de inicio y no se pase de la hora de cierre
    let dateStart: Date;
    // Si la hora actual es menor que la hora de inicio, empieza en la hora de inicio
    if (currentDate < storeOpeningDate) {
      dateStart = new Date(storeOpeningDate.getTime());
    } else {
      // empieza en 15 minutos despues de la hora actual
      dateStart = new Date(currentDate.getTime() + 15 * 60000);
      // asignar y ajustar la variable dateStart al siguiente muultiplo de 5 minutos
      if (dateStart.getMinutes() % 5 !== 0) {
        dateStart.setMinutes(dateStart.getMinutes() + (5 - (dateStart.getMinutes() % 5)));
      }
    }

    datesAdded.push({
      id: datesAdded.length + 1,
      name: `aprox. 15 minutos`,
      orderDateHour: dateStart, // Almacena la hora actual + 15 minutos
      formattedHour: this.formatTime(dateStart),
      quantity: 100,
      itemCode: '1',
    });
    dateStart.setMinutes(dateStart.getMinutes() + 5);
    //se generan las horas hasta llegar a la hora cierre de la tienda o al final del rango
    while (dateStart <= endDate && dateStart <= storeClosingDate) {
      datesAdded.push({
        id: datesAdded.length + 1,
        name: this.formatTime(dateStart),
        orderDateHour: dateStart,
        formattedHour: this.formatTime(dateStart),
        quantity: 100,
        itemCode: '1',
      });
      //se suman los 5 minutos para la siguiete opcion
      dateStart.setMinutes(dateStart.getMinutes() + 5);
    }

    //si no se generan las horas,se mostrara el mensaje de que no esta disponible
    if (datesAdded.length === 0) {
      datesAdded.push({
        id: 1,
        name: `No hay horas disponibles`,
        orderDateHour: null,
        formattedHour: '',
        quantity: 0,
        itemCode: '0',
      });
    }
    //horas generadas de la lista
    this.hours = datesAdded;

    if (this.hours.length > 0) {
      this.selectedTimeSlot = this.hours[0];
    }
  }

  updateGeneratedHours() {
    const currentDate = new Date();
    //verificar si la hora ha cambiado
    if (currentDate.getMinutes() % 15 === 0 && currentDate.getSeconds() === 0) {
      //generar horas
      this.generatedHour(currentDate);
    }
  }

  onOpenTimeSelect(isOpened: boolean) {
    if (isOpened) {
      //generar horas al abrir el select
      this.generatedHour(new Date());
    }
  }

  private formatTime(date: Date): string {
    let hours: number = date.getHours();
    const minutes: string = String(date.getMinutes()).padStart(2, '0');
    const ampm: string = hours >= 12 ? 'P.M.' : 'A.M.';
    hours = hours % 12;
    hours = hours ? hours : 12;
    return `${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;
  }

  getOrderDescription(orderType: string): string {
    switch (orderType) {
      case 'PR':
        return 'Consumir en el restaurante';
      case 'RP':
        return 'Para llevar';
      case 'RC':
        return 'Recoge en autoservicio';
      default:
        return 'Opción desconocida';
    }
  }

  // calculate cart total
  calculatetotal(total: any) {
    this.total = 0
    this.carts.forEach((element: any) => {
      this.total += parseFloat(element.price)
    });
    return this.total.toFixed(2)
  }

  calculateTotalPrice() {
    if (this.cartList.length === 0) {
      this.totalPrice = 0;
    } else {
      this.totalPrice = this.cartList.reduce((acc, product) => {
        return acc + (product.quantity * Number(product.price));
      }, 0);
    }
    this.ecommerceService.totalPriceChange.next(this.totalPrice);
  }

  loadCart() {
    this.ecommerceService.getCart().subscribe(cartItems => {
      this.cartList = cartItems;
      console.log("Carrito actualizado: ", this.cartList);
    });
  }

  // tslint:disable-next-line: typedef
  windowScroll() {
    const navbar = document.querySelector('.navbar-sticky');
    if (document.body.scrollTop > 350 || document.documentElement.scrollTop > 350) {
      navbar?.classList.add('navbar-stuck');
      document.querySelector(".btn-scroll-top")?.classList.add('show');
    }
    else {
      navbar?.classList.remove('navbar-stuck');
      document.querySelector(".btn-scroll-top")?.classList.remove('show');
    }
  }

  // remove from cart
  removecart(materialId: number) {
    // Llama al servicio para eliminar el producto del carrito
    this.ecommerceService.removeCart(materialId).subscribe({
      next: (response) => {
        // Actualiza la lista de productos en el carrito filtrando el producto eliminado
        this.cartList = this.cartList.filter(product => product.id !== materialId);
        // Actualiza la lista del carrito en el servicio
        this.ecommerceService.Data.cartList = this.cartList;
        // Actualiza el total y la cuenta de productos en el carrito
        this.calculateTotalPrice();
        // Guarda los datos del carrito en el almacenamiento local
        this.ecommerceService.setStorageData();
      },
      error: (error) => {
        console.error('Error al eliminar el producto:', error);
      }
    });
  }

  //Mode order
  setSubOrder(data: any) {
    if (this.mode == null) {
      this.mode = new ModeOrder()
    }
    //TODO this.ecommerce.resetCart()
    this.mode.orderSelected = 'pickup'
    this.mode.subOrderSelected = data
    this.mode.storeSelected = new Store()
    this.orderModeService.updateCurrentModeOrder(this.mode)
    this.router.navigate(["/ubicacion-restaurantes"])
  }

  logout() {
    this.authService.logout()
  }

  /***
* Language Listing
*/
  listLang = [
    { text: 'Español', flag: 'assets/img/flags/es.png', lang: 'es', language: 'Es / $' },
    { text: 'English', flag: 'assets/img/flags/en.png', lang: 'en', language: 'Eng / $' }
  ];

  /***
   * Language Value Set
   */
  setLanguage(text: string, lang: string, flag: string, language: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.selectedLanguage = language
    this.languageService.setLanguage(lang);
  }

  ngOnDestroy() {
    this.obs.forEach(element => {
      element.unsubscribe()
    });
  }



  getListCart() {
    this.ecommerceService.getCart().subscribe({
      next: async (res: any) => {
        if (res && Array.isArray(res)) {
          this.ecommerceService.Data.cartList = res;
          this.ecommerceService.updateTotalAndCount()
          this.ecommerceService.setStorageData()
          this.cartList = this.ecommerceService.Data.cartList;
        } else {
          this.cartList = [];
        }
        console.log("el cart", this.cartList)
      },
      error: (error) => {
        this.errorService.getErrorMessage(error)
      }
    })
  }

  getAdditionalDetailsForCart(item: any) {
    return this.additionalService.getAddedItemsDetails(item.aditional);
  }

  /**
  * Returns form
  */
  get form() {
    return this.formData.controls;
  }



  calculateTotals(): { subtotal: number; vat: number; total: number } {
    const vatRate = 0.15;
    const total = this.cartList.reduce((sum, product) => {
      const price = parseFloat(product.material_json.newPrice) || 0;
      const quantity = product.quantity || 0;
      return sum + (price * quantity);
    }, 0);

    const vat = getISVofTotal(total.toString(), (vatRate * 100).toString());
    const subtotal = total - vat;
    return { subtotal, vat, total };
  }
  materialJSON() {
    const data = this.cartList.reduce((acc, product) => {
      const additionalDetails = this.getAdditionalDetailsForCart(product.material_json);
      console.log("adicionales", additionalDetails);
      const aditionalDetail = additionalDetails.map((aditional: Aditional) => ({
        material: aditional.code,
        required: aditional.required,
        quantity: aditional.quantity,
        status: '1'
      }));

      const materialName = `${product.material_json?.code} ${product.material_json?.name}`; // Incluye el código y el nombre del material
      const aditionalNames = additionalDetails.map((ad: Aditional) => `${ad.name}`); // Formato: "1xPequeño (8oz)"
      const aditionalQuantity = additionalDetails.map((ad: Aditional) => ad.quantity);
      acc.push({
        nameProduct: product.material_json.name,
        material: product.material_json?.code,
        quantity: product.quantity,
        price: product.price,
        priceOriginal: product.material_json?.oldPrice ?? product.price,
        discountPrice: product.material_json?.newPrice ?? product.price,
        discountTwoPrice: product.material_json?.newpricetwo ?? product.price,
        type: product.material_json?.type,
        aditionalHead: additionalDetails.length > 0
          ? {
            name: aditionalNames,
            price: additionalDetails.reduce((total: number, ad: Aditional) => total + (ad.price ?? 0), 0),
            quantity: additionalDetails.length,
            type: product.material_json?.aditional?.aditionalType?.id
          }
          : null,
        aditionalDetail: aditionalDetail,
        aditionalDetails: additionalDetails,
        specs: {
          COLOR_TYPE: null,
          SIZE_TYPE: null,
          HEIGHT_TYPE: null
        },
        note: `${product.material_json?.note}`,
        subTotal: `${(product.price ?? 0.0) * (product.quantity ?? 1)}`,
        isPromotion: '0',
        promotionGroupCode: null,
        promotionPointsUsed: null
      });

      return acc;
    }, []);
    console.log("dataa", data);

    return data;
  }

  setprice(price: any) {
    return price.toFixed(2)
  }

  applycode() {
    this.submitted = true
  }

  //seleccionar hora
  onSelectTimeSlot(timeSlot: OrderDateHour) {
    this.selectedTimeSlot = timeSlot;
  }

  generateOrder() {
    if (!this.cartList || this.cartList.length === 0) {
      console.error("El carrito está vacío. No se puede generar una orden.");
      this.snackBar.open("El carrito está vacío. No se puede generar una orden.", '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      return;
    }

    this.isLoading = true;

    let totalTax = 0;
    let totalAmount = 0;
    if (this.cartList && this.cartList.length > 0) {
      this.cartList.forEach(product => {
        const price = product.material_json?.newPrice;
        const quantity = product.quantity || 0;

        // Registro de información para depuración
        console.log(`Producto: ${product.material_json?.name}, Precio: ${price}, Cantidad: ${quantity}`);

        if (price === undefined) {
          console.error("newPrice no está definido para el producto:", product);
          return; // Opcional: Puedes manejar el caso aquí
        }

        totalAmount += price * quantity;
        const productTax = getISVofTotal(price.toString(), this.deliveryTax.toString());

        // Registro de información del impuesto
        console.log(`Impuesto para ${product.material_json?.name}: ${productTax}, Total hasta ahora: ${totalTax}`);

        totalTax += productTax * quantity;
      });

      // Registro de totales al finalizar el bucle
      console.log(`Total de la orden: ${totalAmount}, Total de impuestos: ${totalTax}`);
    }


    totalTax = parseFloat(totalTax.toFixed(2));
    totalAmount = parseFloat(totalAmount.toFixed(2))
    this.totalOrderAmount = totalAmount;
    let deliveryHour = null;
    if (this.selectedTimeSlot) {

      let selectedName = this.selectedTimeSlot.name;
      this.ecommerceService.setDeliveryName(selectedName)

      deliveryHour = this.selectedTimeSlot.formattedHour;
      deliveryHour = deliveryHour.replace(/\s*[AP]\.?M\.?$/, '').trim();
    }
    if (totalAmount <= 0) {
      console.error("El total del pedido debe ser mayor que cero");
      return;
    }
    const orderDetails = this.materialJSON();
    let send = {
      bussinessPartner: this._user.bp_id,
      deliveryType: 1,
      addressId: this.modeOrderService.currentModeOrderValue.storeSelected.id,
      deliveryDate: moment().format("YYYY-MM-DD"),
      deliveryHour: deliveryHour,
      creationDate: moment().format("YYYY-MM-DD"),
      creationHour: moment().format("HH:mm"),
      pdProgrammed: 0,
      priceDelivery: 0,
      deliveryTax: this.deliveryTax,
      exempt: 0,
      taxed: 0,
      tax: totalTax,
      total: totalAmount,
      flagInsurance: 0,
      flagThirdAge: 0,
      flagPrescription: 0,
      insuranceId: null,
      bpThirdAgeId: null,
      flagPartnership: 0,
      partnership: null,
      pos: 1,
      bussinessPartnerProvider: PROVIDER.providerId,
      storeId: this.modeOrderService.currentModeOrderValue.storeSelected.id,
      codeOffice: this.modeOrderService.currentModeOrderValue.storeSelected.office_code,
      deliveryDatePickUp: moment().format("HH:mm"),
      pickup: this.modeOrderService.currentModeOrderValue.subOrderSelected,
      tip: 0,
      detail: orderDetails,
    };

    //almacenar el deliveryhour para enviarlo luego
    this.ecommerceService.setDeliveryHour(send.deliveryHour)

    this.ecommerceService.setAdditionalDetails(this.materialJSON())

    console.log("enviar antes de la api", send);
    this.orderService.generateOrder(send).subscribe({
      next: (res: any) => {
        this.snackBar.open("La orden se ha generado correctamente.", 'x', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        console.log(res, "Respuesta enviada");
        const orderId = res.data;
        this.orderGenerated.emit();
        this.orderService.setOrderCode(orderId);
        this.orderService.getOrderByCode(orderId).subscribe(data => {
          this.orderService.setOrderDetails(data)
        })
        this.getOrderByCode(orderId)
        this.getListCart()
        this.router.navigate(['/checkout']);
        this.isLoading = false;
      },
      error: (error) => {
        console.log('error', error);
        this.snackBar.open(error.error.message || "Error desconocido", '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
        this.isLoading = false
      }
    });
  }

  getOrderByCode(code: string) {
    this.orderService.getOrderByCode(code).subscribe({
      next: (orderData) => {
        console.log('Datos de la orden desde cart', orderData);
        this.orderDetails = orderData;
      },
      error: (error) => {
        console.error('Error al obtener la orden:', error);
        this.snackBar.open(error.error.message || "Error desconocido al obtener la orden", '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      }
    });
  }

  checkout(): void{
    const dialogRef = this.dialog.open(ConfirmComponent)

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.generateOrder()
      }
    })
  }
}


export function getISVofTotal(price: string, tax: string) {
  let p = Number(price); // precio del producto
  let t = Number(tax); // porcentaje de impuesto
  let isv = p - (p / (1 + (t / 100))); // cálculo del ISV
  return isv;
}
