<!-- <section class="bg-secondary bg-size-cover bg-position-center pt-5">
  <div class="container pt-2 pt-sm-0">
    <div class="row align-items-center">
      <div class="col-xl-4 col-lg-5 col-sm-6 offset-xl-2 offset-lg-1">
        <img class="d-block mx-auto mx-sm-0" src="assets/img/food-delivery/phone.png" width="331" alt="Mobile App Screen">
      </div>
      <div class="col-lg-5 col-sm-6 offset-lg-1 pt-5 pt-sm-3 mt-md-n5 text-center text-sm-start">
        <h2 class="text-light mb-3">Descarga nuestra APP</h2>
        <p class="text-light opacity-70 mb-0 d-block d-sm-none d-md-block">Disfrutá de todos sus beneficios</p>
        <div class="mt-4 pt-2">
          <a class="btn-market btn-apple me-3 mb-2" href="https://apps.apple.com/us/app/casa-del-caf%C3%A9/id1536908037" role="button">
            <span class="btn-market-subtitle">Download on the</span>
            <span class="btn-market-title">App Store</span>
          </a>
          <a class="btn-market btn-google mb-2" href="https://play.google.com/store/apps/details?id=ni.com.casadelcafe&hl=es_CO&gl=US" role="button">
            <span class="btn-market-subtitle">Download on the</span>
            <span class="btn-market-title">Google Play</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</section> -->

<footer class="footer footer-color pt-4 pb-1">
  <div class="container pt-1">
    <div class="row d-flex align-items-center p-0 m-0">
      <div class="col-lg-2 col-md-3 col-sm-4 pb-2 mb-2">
        <div class="mt-n1"><a class="d-inline-block align-middle" href="javascript:void(0);"><img class="d-block mb-2"
              src="assets/img/footer-logo-light.png" width="117" alt="Casa del Café"></a></div>
        <!-- <div ngbDropdown class="btn-group dropdown disable-autohide">
              <button class="btn btn-outline-light border-light btn-sm dropdown-toggle px-2" type="button" data-bs-toggle="dropdown" ngbDropdownToggle>
                <img class="me-2" src="{{flagvalue}}" width="20" alt="">{{selectedLanguage}}
              </button>
              <ul ngbDropdownMenu class="dropdown-menu my-1">
                @for(item of listLang;track $index){
                <li (click)="setLanguage(item.text, item.lang, item.flag,item.language)">
                  <a class="dropdown-item pb-1" [ngClass]="{'active': cookieValue === item.lang}" href="javascript:void(0);">
                    <img class="me-2" src="{{item.flag}}" width="20" alt="{{item.text}}">{{item.text}}
                  </a>
                </li>
              }
              </ul>
            </div> -->
      </div>
      <div class="col-lg-10 col-md-9 d-flex justify-content-center p-0 m-0">
        <div class="row w-100 justify-content-center p-0 m-0">
          <div class="col-lg-3 col-md-4 col-sm-6 pb-2 mb-2">
            <div class="widget widget-links widget-light pb-2 mb-2">
              <h3 class="widget-title text-light">{{"FOOTER.JOIN_US" | translate}}</h3>
              <ul class="widget-list">
                <li class="widget-list-item"><a class="widget-list-link" href="./cdc-rewards">{{"FOOTER.PART_HOME" |
                    translate}}</a></li>
                <!-- <li class="widget-list-item"><a class="widget-list-link" href="javascript:void(0);">{{"FOOTER.DYNAMIC_YEARS" | translate}}</a></li> -->
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 pb-2 mb-2">
            <div class="widget widget-links widget-light pb-2 mb-2">
              <h3 class="widget-title text-light">{{"FOOTER.HELPFUL_LINK" | translate}}</h3>
              <ul class="widget-list">
                <li class="widget-list-item"><a class="widget-list-link" href="javascript:void(0);" routerLink="/contactanos/">{{"FOOTER.CONTACT_US" |
                    translate}}</a></li>
              </ul>
            </div>
          </div>
    
          <div class="col-lg-3 col-md-4 col-sm-6 pb-2 mb-2">
            <div class="widget widget-links widget-light pb-2 mb-2">
              <h3 class="widget-title text-light">{{"FOOTER.CONDITIONS&POLICY" | translate}}</h3>
              <ul class="widget-list d-flex flex-wrap justify-content-md-start">
                <li class="widget-list-item"><a class="widget-list-link fs-ms" href="javascript:void(0);"
                    routerLink="/terminos-y-condiciones-de-uso/" (click)="ngContentStart()">{{"FOOTER.TERMS_CONDITIONS" | translate}}</a></li>
                <li class="widget-list-item"><a class="widget-list-link fs-ms" href="javascript:void(0);"
                    routerLink="/politica-general-sobre-la-privacidad-en-linea/" (click)="ngContentStart()">{{"FOOTER.PERSONAL_POLICY" |
                    translate}}</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="hr-light mt-md-2 mb-1">
    <div class="d-md-flex justify-content-between pt-2 pb-0">
      <div class="pb-2 fs-xs text-light opacity-50 text-center text-md-start pt-2">©  2024 <a class="text-light" href="https://www.albatrosservices.com/" target="_blank" rel="noopener">Albatros Services</a>
      </div>

      <div class="col-lg-4 col-md-4 col-sm-6">
        <div class="widget widget-links widget-light pb-2 mb-2">
          <ul class="widget-list d-flex flex-wrap justify-content-center">
            @if (company.facebook){
            <li class="widget-list-item mx-2"><a class="widget-list-link" href="{{company.facebook}}" target="_blank">
              <mat-icon class="social-icon">
                <svg viewBox="0 0 24 24" matTooltip="Facebook"><path d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M18,5H15.5A3.5,3.5 0 0,0 12,8.5V11H10V14H12V21H15V14H18V11H15V9A1,1 0 0,1 16,8H18V5Z" /></svg>
              </mat-icon></a></li>
            }
            @if (company.instagram){
            <li class="widget-list-item mx-2"><a class="widget-list-link" href="{{company.instagram}}" target="_blank"><mat-icon class="social-icon"> <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24"><path fill="currentColor" d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4zm9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3"/></svg></mat-icon></a></li>
            }

            @if (company.youtube){
            <li class="widget-list-item mx-2"><a class="widget-list-link" href="{{company.youtube}}" target="_blank">
              <mat-icon class="social-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="1.05em" height="1.2em" viewBox="0 0 448 512"><path fill="currentColor" d="m282 256.2l-95.2-54.1v108.2zM384 32H64C28.7 32 0 60.7 0 96v320c0 35.3 28.7 64 64 64h320c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64m14.4 136.1c7.6 28.6 7.6 88.2 7.6 88.2s0 59.6-7.6 88.1c-4.2 15.8-16.5 27.7-32.2 31.9C337.9 384 224 384 224 384s-113.9 0-142.2-7.6c-15.7-4.2-28-16.1-32.2-31.9c-7.6-28.6-7.6-88.2-7.6-88.2s0-59.7 7.6-88.2c4.2-15.8 16.5-28.2 32.2-32.4C110.1 128 224 128 224 128s113.9 0 142.2 7.7c15.7 4.2 28 16.6 32.2 32.4"/></svg>
              </mat-icon></a></li>
            }
            @if (company.twitter){
            <li class="widget-list-item mx-2"><a class="widget-list-link" href="{{company.twitter}}" target="_blank">
              <mat-icon class="social-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="1.05em" height="1.2em" viewBox="0 0 448 512"><path fill="currentColor" d="M64 32C28.7 32 0 60.7 0 96v320c0 35.3 28.7 64 64 64h320c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64zm297.1 84L257.3 234.6L379.4 396h-95.6L209 298.1L123.3 396H75.8l111-126.9L69.7 116h98l67.7 89.5l78.2-89.5zm-37.8 251.6L153.4 142.9h-28.3l171.8 224.7h26.3z"/></svg>
              </mat-icon></a></li>
            }
            @if (company.google){
            <li class="widget-list-item mx-2"><a class="widget-list-link" href="{{company.google}}" target="_blank">
              <mat-icon class="social-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16"><path fill="currentColor" d="M15.545 6.558a9.4 9.4 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.7 7.7 0 0 1 5.352 2.082l-2.284 2.284A4.35 4.35 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.8 4.8 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301c1.078 0 2.004-.276 2.722-.764h-.003a3.7 3.7 0 0 0 1.599-2.431H8v-3.08z"/></svg>
            </mat-icon></a></li>
            }
          </ul>
        </div>
      </div>
      
    </div>
  </div>
</footer>

<!-- Back To Top Button-->
<a class="btn-scroll-top" href="javascript:void(0)" [ngxScrollTo]="'#home'" data-scroll>
  <span class="btn-scroll-top-tooltip text-muted fs-sm me-2">Top</span>
  <i class="btn-scroll-top-icon ci-arrow-up"></i>
</a>