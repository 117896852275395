import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AdditionalService {

    constructor() { }

    getAddedItemsDetails(aditional: any): any[] {
        let addedItems: any[] = [];
    
        if (aditional && aditional.aditionalDetail) {
          aditional.aditionalDetail.forEach((item: any) => {
            // Si el item tiene hasBeenAdded === true, lo agregamos
            if (item.hasBeenAdded) {
              addedItems.push({
                code: item.material.code,
                name: item.material.name,
                price: item.price,
                quantity: item.quantity,
                required: item.required
              });
            }
    
            // Verificar si el item tiene un material con aditionalDetail anidado
            if (item.material && item.material.aditional && item.material.aditional.aditionalDetail) {
              // Llamada recursiva para seguir bajando en niveles
              const nestedItems = this.getAddedItemsDetails(item.material.aditional);
              addedItems = addedItems.concat(nestedItems); // Agregar los elementos del nivel más profundo
            }
          });
        }
    
        return addedItems;
    }
}
