import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContinuarComprandoComponent } from 'src/app/pages/cart/continuar-comprando/continuar-comprando.component';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { EcommerceService } from 'src/app/services/ecommerce/ecommerce.service';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrl: './controls.component.scss'
})
export class ControlsComponent {
  @Input() product: any; // TODO: CAMBAIR A ALA CLASE PRODUCT

  @Input() aditional?: any = null; // TODO: solo si el producto es de tipo A
  @Input() aditionalSelected?: any; // TODO: solo si el producto es de tipo A
  @Input() note: string = '';
  private _user: any = null;

  constructor(
    public ecommerceService: EcommerceService,
    public snackBar: MatSnackBar,
    private authService: AuthenticationService,
    private router: Router,
    private modalService: NgbModal
  ){
    this.authService.currentUser.subscribe(res => {
      this._user = res ? res.user : null;
    });
  }

  ngOnInit() {
    if (!this.aditionalSelected) {
      this.aditionalSelected = { selected: [] };
    }
  }
  quantity: number = 1;
  cartItemCount: number = 0;

  plus() {
    this.quantity++;
    console.log('Cantidad aumentada:', this.quantity);
  }

  minus() {
    if (this.quantity > 1) {
      this.quantity--;
      console.log('Cantidad disminuida:', this.quantity);
    }
  }

  public async addToCart(product: any) {
    if (!this._user) {
      this.snackBar.open('Por favor, inicie sesión para agregar productos al carrito', '×', {
        panelClass: 'error',
        verticalPosition: 'top',
        duration: 5000
      });

      setTimeout(() => {
        this.router.navigate(['/auth/ingresar']);
      }, 2000);

      return;
    }
    console.log('Producto recibido en addToCart:', product);
    console.log('Cantidad actual:', this.quantity);
    if (product.type == 'A') {
      if (this.aditional != null && (this.onVerifiedQuantitySubnivel(this.aditionalSelected))) {
        this.snackBar.open('Por favor, completa las selecciones requeridas', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        return
      }
    }

    let det = []
    if (this.aditional != null) {
      det = this.aditionalSelected.selected.slice()
    }

    let additionalDetail = []
    for (const mat of det) {
      if (mat.material.type == null) {
        additionalDetail.push(mat)
      } else {
        for (const mad of mat.aditionalSelected.selected) { // si es de tipo "A"
          additionalDetail.push(mad)
        }
      }
    }

    product.note = product.note || '';

    product.cartCount = this.quantity;

    let auxproduct = Object.assign({}, product)
    if (product.type == 'A') {
      auxproduct.aditional.aditionalDetail = this.parseAdditional(auxproduct.aditional, additionalDetail);
      auxproduct.newPrice = this.getTotalItemSelected()
    }

    console.log('Producto a agregar al carrito:', auxproduct);
    this.ecommerceService.addToCart(auxproduct);
    this.cartItemCount += this.quantity;
    this.quantity = 1
    const modalRef = this.modalService.open(ContinuarComprandoComponent);
    modalRef.componentInstance.itemCount = this.cartItemCount;

  }

  parseAdditional(aditional: any, aditionalDetail: any){
    let listAditional = aditional.aditionalDetail.map((item: any) => {
      const hasDetails = aditionalDetail.some((detail: any) => detail.id === item.id);
      item.hasBeenAdded = hasDetails ? true : false;
      item.isActiveToAddInApp = null;

      if (item.material.type == 'A') {
          item.material.aditional.aditionalDetail = item.material.aditional.aditionalDetail.map((item2: any) => {
            const hasDetailsInner = aditionalDetail.some((detail: any) => detail.id === item2.id);

            return {
                ...item2,
                hasBeenAdded: hasDetailsInner ? true : false,
                isActiveToAddInApp: null,
            };
          });
      }

      return item;
    });

    return listAditional;
  }

  onVerifiedQuantitySubnivel(aditionalSelected: any) {
    let found = false
    for (let item of aditionalSelected.selected) {
      if (item.material.type == 'A' && item.material.aditional.quantity > 0) {
        let sum = 0
        for (let i of item.aditionalSelected.selected) {
          sum = sum + i.quantity
        }
        if (!(sum >= item.material.aditional.minQuantity && sum <= item.material.aditional.quantity)) {
          found = true
          break
        }
      }
    }
    return found
  }

  getTotalItemSelected() {
    let res = 0
    if (this.product == null) {
      return 0
    }
    res = Number(this.product.newPrice)
    if (this.product.type == 'A') {
      for (let item of this.aditionalSelected.selected) {
        if (item.material.type == 'A') {
          item.aditionalSelected.selected.forEach((element:any) => {

            if (element.isAditionalPrice == '1') {
              res = res + (Number(element.price) * element.quantity)
            }
          });
        }
      }
    }
    return res
  }
}
